<template>
<Card>
    <template #content>
		<div v-if="!errores">
        	<p>Listado preliminar de lecturas a ser procesadas.</p>
		</div>
		<div v-else>
			<p>No es posible continuar debido a que se han encontrado inconsistencias en el archivo de importación. Por favor revise los errores encontrado e intente de nuevo.</p>
		</div>
        <Divider />      		    
        <DataTable :value="lecturas" editMode="cell" dataKey="id_contador_lectura" :rowClass="rowClass" v-model:expandedRows="expandedRows" responsiveLayout="scroll" class="p-datatable-sm"
		filterDisplay="menu">
			<template #header>        
				<div class="p-d-flex p-jc-end">
					<Button label="Procesar Lecturas" @click="procesar()" class="p-button-warning" icon="pi pi-cog" v-show="!errores" :disabled="totalTrxAEmitir==0"/>
				</div>
			</template>		
			<template #empty>
				No hay lecturas a procesar 
			</template>		
			<Column :expander="true" headerStyle="width: 3rem" />   
			<Column field="err_msg" header="Error" :style="errores?'':'display: none'">
                <template #body="slotProps">
                   	<div style="font-weight: 700;color: #FF5252;" class="p-d-flex p-ai-center">
						<icon class="pi pi-ban p-mr-2" style="font-size: 2rem;color: #FF5252;"/>
                        <div>{{slotProps.data.err_msg}}</div>
                    </div>
                </template>
			</Column>	
			<Column field="numero_contador"  :sortable="true"  header="Número Contador" />
			<Column field="inmueble" :sortable="true"  header="Inmueble" /> 
			<Column field="tarifa" :sortable="true"  header="Tarifa" /> 
            <Column field="total_cargo"  :sortable="true" header="Total" headerStyle="text-align:right"> 
				<template #body="slotProps">
                   	<div class="p-d-flex p-jc-end" style="font-weight: 700">
                        <div>{{moneda_simbolo}} {{formatCurrency(slotProps.data.total_cargo)}}</div>
                    </div>                									
				</template>
			</Column>	
            <template #expansion="slotProps">
                <div class="orders-subtable">
                    <DataTable :value="slotProps.data.detalle" dataKey="codigo" responsiveLayout="scroll" class="p-datatable-sm"  selectionMode="single">
                        <Column field="codigo" header="Código" style="flex: 0 0 8rem"></Column>
                        <Column field="servicio" header="Servicio" style="flex: 0 0 2rem"></Column>
                        <Column field="cantidad" header="Cantidad" ></Column>
                          <template #body="slotProps">
								<div class="p-d-flex p-jc-end">
                                	{{ slotProps.data.cantidad }}
								</div> 	
                            </template>
						<Column field="precio_unitario" header="Precio U.">
                          <template #body="slotProps">
								<div class="p-d-flex p-jc-end">
                                	{{moneda_simbolo}} {{formatCurrency(slotProps.data.precio_unitario)}}
								</div> 	
                            </template>
                        </Column>
						<Column field="valor_total" header="Valor">					
                            <template #body="slotProps">
								<div class="p-d-flex p-jc-end">
									<div>{{moneda_simbolo}} {{formatCurrency(slotProps.data.valor_total)}}</div>
								</div>      								
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template>
			<ColumnGroup type="footer">
				<Row>
					<Column footer="Documentos:" :colspan="2" footerStyle="text-align:right" />
					<Column :footer="totalTrxAEmitir" />
					<Column :footer="montoTotalAOperar" :colspan="2" footerStyle="text-align:right" />
					<!-- <Column :footer="montoTotalAOperar" />					 -->
				</Row>
			</ColumnGroup>					
        </DataTable>				
    </template>
    <template #footer>        
        <div class="p-d-flex p-jc-between">
            <Button label="Regresar"  @click="prevPage()" icon="pi pi-angle-left"/>
            <Button label="Procesar Lecturas" @click="procesar()" class="p-button-warning" icon="pi pi-cog" v-show="!errores" :disabled="totalTrxAEmitir==0"/>
        </div>
    </template>
</Card>

<Dialog v-model:visible="procesandoDlg" :showHeader="true" :modal="true" :closable="false">			
	<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
		<div class="p-d-flex p-ai-center p-jc-center p-mb-2">
			<img src="/assets/layout/images/document.gif" width="64" height="64"/>
		</div>		
		<div class="p-d-flex p-ai-center p-jc-center p-text-bold">			
            <div class="text-lg">Por favor espere un minuto mientras se procesan las lecturas...</div>
		</div>
	</div>
</Dialog>
</template>
<script>
import MdsService from '../../../service/mds.service';
import { CURRENT_APP_MODE, MDS_APP_MODE } from '../../../service/constants';
import Divider from 'primevue/divider';

export default {
    name: 'ImpContRevision',
    components: {
        Divider
    },    
	props: {
		formData: {
			type: Object,
			Default() {
				return {
					id_perfil: '',
					id_moneda: '',
					moneda_simbolo: '',
					tipo: 0,
					procesados: 0,
					total_procesado: 0.0,					
					archivo: '',
					errores: false,
					lecturas: [],					
				}
			}
		}
	},          
    data() {        
        return {         
            appMode: CURRENT_APP_MODE,
			procesandoDlg: false,
			id_perfil: this.formData.id_perfil,
			id_moneda: this.formData.id_moneda,
			moneda_simbolo: this.formData.moneda_simbolo,
			tipo: this.formData.tipo,
			procesados: this.formData.procesados,
			total_procesado: this.formData.total_procesado,					
			errores: this.formData.errores,
			lecturas: this.formData.lecturas,
			gran_total: 0.0,
			expandedRows: [],
        }
    },
    async mounted()
    {
		this.gran_total = 0;
		for(var i = 0; i < this.lecturas.length; i++)
		{
			this.gran_total = this.gran_total + this.lecturas[i].total_cargo;			
		}
    },
	computed:{
		totalTrxAEmitir()
		{
			if(this.lecturas) {
				return this.lecturas.length;
			} else {
				return 0;
			}
		},
		montoTotalAOperar()
		{
			return `Total: ${this.moneda_simbolo} ${this.formatCurrency(this.gran_total)}`;
		}			
	},    
    methods: {
		async procesar() {
			this.procesandoDlg = true;
			try {
				
				let movs = await MdsService.procesarLecturasBatch(this.$store.state.auth.currentAppCtx, this.id_perfil, this.lecturas);

				this.tipo = movs.tipo;
				this.procesados  = movs.lecturas;
				this.total_procesado = movs.total;
				
				this.procesandoDlg = false;

				this.nextPage();
			} catch(err) {
				this.procesandoDlg = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
			}
		},
        async nextPage() {
			// var emisiones = await this.cargos.filter(cargo => cargo.emitido);

			let formData = {
				id_perfil: this.id_perfil,
				id_moneda: this.id_moneda,
				moneda_simbolo: this.moneda_simbolo,	
				tipo: this.tipo,
				procesados: this.procesados,
				total_procesado: this.total_procesado,				
				archivo: this.archivo,
				errores: this.errores,
				lecturas: this.lecturas,
			};

            this.$emit('nextPage', {condiciones: formData, pageIndex: 1});
        },
        onRowSelect(data) {
            data.aplicar = !data.aplicar;				
			return data.aplicar;			
        },
		rowClass(data)
		{
			var className = (data.emitir?"emitir-row":"regular-row");
			return className;
		},
		findIndexMovimiento(idMovimiento)
		{
			var index = -1;
			for(var i=0; i<this.lecturas.length; i++)
			if(this.lecturas[i].id_movimiento === idMovimiento) {
				index = i;
				break;
			}

			return index;
		},
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},	        
        prevPage() {
            this.$emit('prevPage', {pageIndex: 1});
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}

::v-deep(.regular-row) {
	font-weight: 300;
	background: white;
}

::v-deep(.emitir-row) {
	font-weight: bold;
	background:white;
}

</style>